import './App.scss';
import React from 'react';
import { Route, Routes, useLocation, useHistory } from 'react-router-dom';
import { Cover, Main, MobileMenu } from './pages';
import { AnimatePresence } from 'framer-motion';

function App() {

  const location = useLocation();

  return (
    <div style={{position: 'relative'}}>
    <div className="grid"></div>
      <AnimatePresence location={location} key={location.pathname} mode='wait'>
        <Routes>
          <Route path='/' element={<Cover/>} />
          <Route path='/me' element={<Main/>} />
          <Route path='/menu' element={<MobileMenu/>} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
